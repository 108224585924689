import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, type ControllerRenderProps, type FieldValues, type UseFormReturn } from "react-hook-form";
import type { ClientToolDef } from "shared/tool";
import { match, P } from "ts-pattern";
import { z } from "zod";
import { Button } from "./ui/button";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "./ui/form";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { mapValues } from "remeda";
import type React from "react";
import { Trash2, Plus } from "lucide-react";

const detectRef = (playgroundId?: string) => (o: unknown) => {
  if (typeof o !== "string") {
    return o;
  }
  if (!o.trim().startsWith(document.location.origin)) {
    return o;
  }
  const result = o.match(/playgrounds\/([^/]+)\/resources\/([^/]+)\/raw/);
  return match(result)
    .with([P._, P.when(x => x === playgroundId), P.select()], resourceId => ({
      $$resourceId: resourceId,
    }))
    .with([P._, P.string, P.string], ([_, playground, resourceId]) => ({
      $$resourceId: `${playground}/${resourceId}`,
    }))
    .otherwise(() => o);
};


type RequiredFieldProps = Partial<ControllerRenderProps<FieldValues, string>> & Pick<ControllerRenderProps<FieldValues, string>, "value" | "onChange">

const ResourceIdField: React.FC<{field: RequiredFieldProps}> = ({field}) => {
  return (
    <Input
      {...field}
      onPaste={e=> {
        const clipboardData = e.clipboardData.getData("text");
        const d = detectRef()(clipboardData)
        if (d !== clipboardData) {
        field.onChange(d);
        e.preventDefault();

        }
      }}
      value={field.value?.$$resourceId || ""}
      onChange={e => field.onChange({ $$resourceId: e.target.value })}
    />
  );
};
const FileUploadField: React.FC<{field: RequiredFieldProps}> = ({field}) => {
  return (
    <Input
      type="file"
      onChange={async (e) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = () => {
          const base64 = (reader.result as string).split(',')[1];
          field.onChange({
            $$file: base64,
            $$mimeType: file.type
          });
        };
        reader.readAsDataURL(file);
      }}
    />
  );
};


const ArrayField: React.FC<{
  fieldType: z.ZodArray<any>;
  field: RequiredFieldProps;
}> = ({ fieldType, field }) => {
  const values = field.value ?? [''];

  return (
    <div className="space-y-2">
      {values.map((_, index) => (
        <div key={index} className="flex gap-2">
          <div className="flex-1">
            <FormFieldInput 
              fieldType={fieldType.element}
              field={{
                value: values[index],
                onChange: val => {
                  const newValue = [...values];
                  newValue[index] = val;
                  field.onChange(newValue);
                }
              }}
            />
          </div>
          
          <Button
            type="button"
            variant="ghost"
            size="icon"
            onClick={() => {
              const newValue = values.filter((_, i) => i !== index);
              field.onChange(newValue.length ? newValue : ['']);
            }}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      ))}
      <Button
        type="button"
        variant="outline"
        size="sm"
        className="flex gap-2"
        onClick={() => {
          field.onChange([...values, '']);
        }}
      >
        <Plus className="h-4 w-4" /> Add Item
      </Button>
    </div>
  );
};

const FormFieldInput: React.FC<{fieldType: z.ZodType, field: RequiredFieldProps}> = ({fieldType, field}) => {
  return match(fieldType)
  .with(P.instanceOf(z.ZodBoolean), () => <Input type="checkbox" {...field} onChange={x => field.onChange(x.target.checked)} />)
  .with(P.instanceOf(z.ZodNumber), () => <Input type="number" {...field} onChange={x => field.onChange(x.target.valueAsNumber)} />)
  .with(P.when((x)=> x instanceof z.ZodObject && x.shape["$$resourceId"]), () => <ResourceIdField field={field} />)
  .with(P.when((x)=> x instanceof z.ZodObject && x.shape["$$file"]), () => <FileUploadField field={field} />)
  .with(P.instanceOf(z.ZodArray), (x) =>  <ArrayField fieldType={x} field={field} />)
  .otherwise(() => (
    <Textarea {...field} />
  ))
}

export const ResourceFromTool = ({
  tool,
  playgroundId,
  onSubmit,
}: {
  tool: ClientToolDef;
  playgroundId?: string;
  onSubmit: (input: unknown) => void;
}) => {
  const schema = tool.args;

  const form = useForm({
    resolver: zodResolver(schema),
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(
          s => {
            let refArgs = mapValues(s, detectRef(playgroundId));
            onSubmit(refArgs);
          },
          err => console.log(err),
        )}
        className="space-y-8"
      >
        {Object.entries(schema.shape).map(([key, value]) => {
          const fieldType = value.isOptional() ? value._def.innerType : value;
          console.log(fieldType);
          return (
            <FormField
              key={key}
              control={form.control}
              name={key}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {value.description || key}
                    {!value.isOptional() && <span className="text-red-500 ml-1">*</span>}
                  </FormLabel>
                  <FormControl>
                    <FormFieldInput fieldType={fieldType} field={field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          );
        })}
        <Button type="submit">Generate</Button>
      </form>
    </Form>
  );
};

import type { UIResource } from "shared/data/resource";

type SuspendData = {
  type: "widget" | "editable";
};

export function getSuspendTraceData<T extends SuspendData>(resource: UIResource) {
  const suspendTraceIndex = resource.trace.findLastIndex(x => x.type === "suspend");
  if (suspendTraceIndex === -1) {
    return null;
  }
  const suspendTrace = resource.trace[suspendTraceIndex];

  return {
    args: suspendTrace.data.args as [T],
    rootContext: suspendTrace.data.rootContext as string,
    contextName: suspendTrace.data.contextName as string,
    index: suspendTraceIndex,
  };
}

export type SuspendTrace<T extends SuspendData> = Exclude<ReturnType<typeof getSuspendTraceData<T>>, null>;

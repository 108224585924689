import { ScrollArea } from "@radix-ui/react-scroll-area";
import { Scroll } from "lucide-react";
import ReactMardown from "react-markdown";
import { ScrollBar } from "./ui/scroll-area";
import remarkGfm from 'remark-gfm'

export const MarkdownWithOriginal: React.FC<{
  children: string;
  className: string;
}> = ({ children, className }) => {
  /*const html = useMemo(()=>micromark(children, {
        allowDangerousHtml: true,

    }), [children]);*/
  return (
    <ScrollArea className="overflow-auto">
      <ScrollBar orientation="vertical" />
      <ReactMardown remarkPlugins={[remarkGfm]} className={`prose prose-slate dark:prose-invert min-w-full ${className}`}>{children}</ReactMardown>
    </ScrollArea>
  );
};

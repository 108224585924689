import type * as W from "./wireframe.types.ts";
import * as React from "react";
import "./wireframe.css";

function propsToCSS({ gravity, maxWidth }: { gravity?: "center" | "start" | "end" | undefined; maxWidth?: number }) {
  return {
    ...(gravity === "center"
      ? ({ alignSelf: "center" } as const)
      : gravity === "start"
        ? ({ alignSelf: "flex-start" } as const)
        : gravity === "end"
          ? ({ alignSelf: "flex-end" } as const)
          : {}),
    ...(maxWidth ? { maxWidth: `calc(${maxWidth} * 100% - 10px)` } : {}),
  };
}

export const Section: W.Section<unknown> = ({ name, children, ...props }) => {
  return (
    <div
      style={{
        position: "relative",
        padding: "8px",
        border: "1px solid black",
        width: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          padding: "0 2px",
          top: 0,
          left: 0,
          backgroundColor: "yellow",
          boxShadow: "0 0 0 1px black",
          color: "black",
        }}
      >
        {name}
      </div>
      {children(props)}
    </div>
  );
};

/**
 * Represents a screen, which is a collection of blocks.
 */
type WScreen = W.WireFrameComponent<{
  /**
   * The device type of the screen.
   */
  device: "mobile" | "tablet" | "desktop";
  /**
   * The children of the screen, which can be one or more sections.
   */
  children: React.ReactElement<W.Section> | React.ReactElement<W.Section>[];
}>;

const Screen: WScreen = ({ device, children }) => {
  return <div className={`screen ${device}`}>{children}</div>;
};

export const Page: W.Page = ({ children }) => {
  return (
    <>
      <Screen device="desktop">{children}</Screen>
    </>
  );
};

export const Chip: W.Chip = ({ children, ...props }) => {
  return (
    <div className="chip" style={{ ...propsToCSS(props) }}>
      {children}
    </div>
  );
};

export const Block: W.Block = ({ name, annotation, children, ...props }) => {
  return (
    <div className="block" style={{ ...propsToCSS(props) }}>
      <h2>{name}</h2>
      {annotation && <p>{annotation}</p>}
      {children}
    </div>
  );
};

export const HStack: W.HStack = ({ children, itemsGravity, ...props }) => {
  const justifyContent =
    itemsGravity === "sparse"
      ? "space-evenly"
      : itemsGravity === "center"
        ? "center"
        : itemsGravity === "start"
          ? "flex-start"
          : itemsGravity === "end"
            ? "flex-end"
            : "center";
  return (
    <div
      className="hstack"
      style={{
        ...propsToCSS(props),
        justifyContent,
        gap: 10,
      }}
    >
      {children}
    </div>
  );
};

export const Wrap: W.Wrap = ({ children, ...props }) => {
  return (
    <div className="wrap" style={{ ...propsToCSS(props) }}>
      {children}
    </div>
  );
};

export const VStack: W.VStack = ({ children, itemsGravity, ...props }) => {
  const alignItems =
    itemsGravity === "sparse"
      ? "space-evenly"
      : itemsGravity === "center"
        ? "center"
        : itemsGravity === "start"
          ? "flex-start"
          : itemsGravity === "end"
            ? "flex-end"
            : "center";
  return (
    <div className="vstack" style={{ alignItems, ...propsToCSS(props) }}>
      {children}
    </div>
  );
};

export const Link: W.Link = ({ href, children, ...props }) => {
  return (
    <a className="link" href={href} style={{ ...propsToCSS(props) }}>
      {children}
    </a>
  );
};

export const FillSpace: W.FillSpace = ({ limit, ...props }) => {
  return <div className="fill-space" style={{ width: limit, ...propsToCSS(props) }} />;
};

export const Button: W.Button = ({ children, size, variant, ...props }) => {
  return (
    <button type="button" className={`button ${size} ${variant}`} style={{ ...propsToCSS(props) }}>
      {children}
    </button>
  );
};

export const Text: W.Text = ({ children, size, ...props }) => {
  return (
    <div className={`text ${size}`} style={{ ...propsToCSS(props) }}>
      {children}
    </div>
  );
};

export const Image: W.Image = ({ placeholder, size, aspectRatio, ...props }) => {
  return (
    <div className={`image ${size}`} style={{ aspectRatio, ...propsToCSS(props) }}>
      <div className="placeholder">{placeholder}</div>
    </div>
  );
};

export const Header: W.Header = ({ children, size, ...props }) => {
  const comp = size === "lg" ? "h1" : size === "md" ? "h2" : "h3";
  return React.createElement(comp, { className: "header", style: { ...propsToCSS(props) } }, children);
};

export const FloatingBlock: W.FloatingBlock = ({ top, left, children, ...props }) => {
  return (
    <div className="floating-block" style={{ top, left, ...propsToCSS(props) }}>
      {children}
    </div>
  );
};

export const Cover: W.Cover = ({ backgroundImagePlaceholder, children, height, ...props }) => {
  return (
    <div
      className={`cover ${height}`}
      style={{
        backgroundImage: `url(${backgroundImagePlaceholder})`,
        ...propsToCSS(props),
      }}
    >
      {children}
    </div>
  );
};

export const Input: W.Input = ({ placeholder, size, ...props }) => {
  return <input className={`input ${size}`} placeholder={placeholder} style={{ ...propsToCSS(props) }} />;
};

export const Icon: W.Icon = ({ name, size, ...props }) => {
  return <i className={`icon ${name} ${size}`} style={{ ...propsToCSS(props) }} />;
};

export const Card: W.Card = ({ children, ...props }) => {
  return (
    <div
      className="card"
      style={{
        ...propsToCSS(props),
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </div>
  );
};

export const CardHeader: W.Card = ({ children, ...props }) => {
  return (
    <div className="card-header" style={{ ...propsToCSS(props) }}>
      {children}
    </div>
  );
};

export const CardBody: W.Card = ({ children, ...props }) => {
  return (
    <div className="card-body" style={{ ...propsToCSS(props), flexGrow: 1 }}>
      {children}
    </div>
  );
};

export const CardFooter: W.Card = ({ children, ...props }) => {
  return (
    <div className="card-footer" style={{ ...propsToCSS(props) }}>
      {children}
    </div>
  );
};

export const Table: W.Table = ({ data, columns, gravity }) => {
  return (
    <div className="table" style={{ ...propsToCSS({ gravity }) }}>
      <table>
        <thead>
          <tr>
            {columns.map(column => (
              <th key={column.key}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map(column => (
                <td key={column.key}>{row[column.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

import { createRoute } from "@tanstack/react-router";
import { layoutRoute } from "../../layout";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { useState } from "react";
import { ofetch } from "ofetch";
import { useNavigate } from "@tanstack/react-router";
import { useAddNewTeam } from "@/state";

export const newTeamRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: "/teams/new",
  component: NewTeam,
});

function NewTeam() {
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const addNewTeam = useAddNewTeam();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await addNewTeam({ name, slug });
    navigate({ to: `/t/${slug}` });
  };

  return (
    <div className="container max-w-2xl py-10">
      <Card>
        <CardHeader>
          <CardTitle>Create New Team</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="name">Team Name</Label>
              <Input
                id="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setSlug(e.target.value.toLowerCase().replace(/[^a-z0-9]/g, "-"));
                }}
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="slug">Team URL</Label>
              <Input
                id="slug"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                required
              />
            </div>
            <Button type="submit">Create Team</Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
} 
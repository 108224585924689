import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { useAtomValue } from "jotai";
import { j_currentTeam, j_teams } from "../state";
import { Link, useNavigate } from "@tanstack/react-router";
import { PlusCircle } from "lucide-react";

export function TeamSwitcher() {
  const currentTeam = useAtomValue(j_currentTeam);
  const teams = useAtomValue(j_teams);
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="gap-2">
          {currentTeam?.name}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Teams</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {teams.map((team) => (
          <DropdownMenuItem key={team.id} asChild>
            <Link to={`/t/${team.slug}`}>{team.name}</Link>
          </DropdownMenuItem>
        ))}
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link to="/teams/new" className="gap-2">
            <PlusCircle className="w-4 h-4" />
            Create New Team
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
} 
import { atom, createStore, useSetAtom, type WritableAtom } from "jotai";
import { atomWithRefresh, useAtomCallback } from "jotai/utils";
import { ofetch } from "ofetch";
import { useCallback, useMemo } from "react";
import type { Team } from "shared/data/team";

export const j_rootStore = createStore();
export const j_currentTeamSlug = atom<string | null>(null);

export const j_teams = atomWithRefresh(async (get)=> {
    const slug = get(j_currentTeamSlug)
    return await ofetch<Team[]>(`/api/teams?slug=${slug}`)
})

export const j_currentTeam = atom(async (get)=> {
    const teams = await get(j_teams)
    const currentTeamSlug = get(j_currentTeamSlug)
    return teams.find(t=> t.slug === currentTeamSlug)
})

export const j_newTeam = atom(null, async (get, set, team: {name:string, slug:string})=> {
    await ofetch("/api/teams", {
        method: "POST",
        body: JSON.stringify(team),
    })
    await set(j_teams)
})
/*
function atomSetterAsHook<T,TArgs extends unknown[],TResult>(atom:WritableAtom<T,TArgs,TResult>) {
    return useAtomCallback(useCallback(async (get, set, ...value:TArgs)=> {
        await set(atom, ...value)
    }, []))
}*/

export const useAddNewTeam = ()=> {
    return useSetAtom(j_newTeam)
}
import { useResource } from "../state";
import { getResourceOutput } from "@/lib/resource-utils";

export const ImagePanel: React.FC<{ resourceRef: string }> = ({ resourceRef }) => {
  const resource = useResource(resourceRef);
  const imageUrl = getResourceOutput(resource)!;
  return (
    <picture>
      <source srcSet={imageUrl} />
      <img src="https://placehold.co/600x400" alt="Fallback" />
    </picture>
  );
};

import { useSession } from "@hono/auth-js/react";
import { createRoute, Link, Outlet } from "@tanstack/react-router";
import { useAtomValue } from "jotai";
import { j_currentTeam } from "./state";
import { Avatar, AvatarImage } from "./components/ui/avatar";
import { rootRoute } from "./root-route";
import { TeamSwitcher } from "./components/TeamSwitcher";

const Layout = () => {
    const user = useSession().data?.user;
    const currentTeam = useAtomValue(j_currentTeam);
  
    return (
      <div className="flex flex-col fixed top-0 left-0 right-0 bottom-0">
        <div className="h-10 flex items-center justify-between px-4 bg-background border-b">
          <Link
            to={currentTeam ? `/t/${currentTeam.slug}` : "/"}
            className="text-sm font-medium text-muted-foreground hover:text-foreground transition-colors"
          >
            Livecycle AI 
          </Link>
          <div className="flex items-center gap-3">
              <TeamSwitcher />
              <div className="h-6 w-[1px] bg-border" />
  
              <div className="text-sm text-muted-foreground">
              {user?.name}
            </div>
            <div className="h-6 w-[1px] bg-border" />
            <a
              href="/api/auth/signout"
              className="text-sm text-muted-foreground hover:text-foreground transition-colors"
            >
              Sign out
            </a>
            <Avatar className="h-6 w-6">
              <AvatarImage src={user?.image ?? ""} />
            </Avatar>
          </div>
        </div>
        <div className="flex-1 overflow-auto">
          <Outlet />
        </div>
      </div>
    );
  }
  
  export const layoutRoute = createRoute({
    getParentRoute: () => rootRoute,
    id: "layout",
    component: Layout,
  });
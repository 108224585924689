import { getResourceOutput } from "@/lib/resource-utils";
import { Button } from "@/components/ui/button";
import { useRef, useState, useCallback, useEffect, useMemo } from "react";
import type { UIResource } from "shared/data/resource";
import { useResourceWSApi } from "../hooks";
import { getSuspendTraceData, type SuspendTrace } from "@/lib/suspend-utils";
import { Input } from "@/components/ui/input";
import { useFloating } from "@floating-ui/react";
import { autoUpdate } from "@floating-ui/react-dom";
import { FileText, Pencil, PictureInPicture, Trash2, Wand2, Smartphone } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { IFrameTitleBar } from "./website";

type InteractiveRemixSuspenseMessages = SelectElementRemixPanel | SelectVariationToApply;

export type SelectElementRemixPanel = {
  type: "editable";
  panelType: "ir-select-element-remix";
  instructions: string;
};

export type SelectVariationToApply = {
  type: "editable";
  panelType: "ir-select-variation-to-apply";
  variations: string[];
  selector: string;
};

type RemixActionType = "remix" | "content" | "edit" | "imageRegen" | "inspire" | "end" | "remove";

export const InteractiveRemixPanel = ({ resource }: { resource: UIResource }) => {
  const resourceApi = useResourceWSApi();
  const data = getResourceOutput(resource);
  const latestTrace = getSuspendTraceData<InteractiveRemixSuspenseMessages>(resource);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [remixingElement, setRemixingElement] = useState<string | null>(null);
  const mode = resource.status === "suspended" ? "waiting-for-input" : "running";
  const [isMobileView, setIsMobileView] = useState(false);
  

  const onElementSelect = useCallback(
    async (o: { action: string; prompt?: string; selector?: string }) => {
      if (!latestTrace) {
        throw new Error("Invalid trace");
      }
      setRemixingElement(o.selector ?? null);
      await resourceApi.release(
        {
          rootResourceId: latestTrace.rootContext,
          triggeringResourceId: latestTrace.contextName,
        },
        { args: latestTrace.args, result: o },
      );
    },
    [latestTrace],
  );

  useEffect(() => {
    if (!remixingElement || mode !== "running" || latestTrace?.args[0].panelType !== "ir-select-element-remix") {
      return;
    }
    const elem = iframeRef.current?.contentDocument?.querySelector(remixingElement) as HTMLElement;
    if (!elem) {
      return;
    }
    elem?.scrollIntoView();
    const previousFilter = elem.style.filter;
    let filterBlur = 2;
    let blurDir = 0.2;
    const interval = setInterval(() => {
      filterBlur += blurDir;
      elem.style.filter = `blur(${filterBlur}px)`;
      if (filterBlur >= 5 || filterBlur <= 2) {
        blurDir *= -1;
        filterBlur = Math.min(5, Math.max(2, filterBlur));
      }
    }, 80);
    elem.className += " border-2 border-orange-500";
    return () => {
      elem.className = elem.className.replace("border-2 border-orange-500", "");
      elem.style.filter = previousFilter;
      clearInterval(interval);
    };
  }, [latestTrace?.args[0].panelType, remixingElement, mode]);

  return (
    <div className="w-full h-full flex flex-col">
      <IFrameTitleBar ref={iframeRef} isMobileView={isMobileView} onMobileViewChange={setIsMobileView}  />
      <iframe 
        ref={iframeRef} 
        className={`bg-white transition-all duration-300 ${
          isMobileView ? 'w-[390px] mx-auto' : 'w-full'
        } flex-1`}
        srcDoc={data || undefined} 
      />
      {latestTrace?.args[0].panelType === "ir-select-element-remix" && mode === "waiting-for-input" ? (
        <SelectElementRemixPanel onAction={onElementSelect} iframeRef={iframeRef} />
      ) : null}
      {latestTrace?.args[0].panelType === "ir-select-variation-to-apply" && mode === "waiting-for-input" ? (
        <SelectVariationRemixPanel 
          resource={resource} 
          iframeRef={iframeRef} 
          trace={latestTrace as SuspendTrace<SelectVariationToApply>}
        />
      ) : null}
    </div>
  );
};

interface ToolbarItemProps {
  icon: React.ReactNode;
  label: string;
  isActive: boolean;
  showLabel: boolean;
  onClick: () => void;
}

const ToolbarItem: React.FC<ToolbarItemProps> = ({ icon, label, isActive, showLabel, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Button
      variant={isActive ? "default" : "ghost"}
      className={`h-10 justify-start gap-2 transition-all duration-300 ${showLabel || isHovered ? "w-40" : "w-10"}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className="flex-shrink-0">{icon}</span>
      <span className={`overflow-hidden whitespace-nowrap transition-all duration-300 ${showLabel || isHovered ? "opacity-100 w-auto" : "opacity-0 w-0"}`}>
        {label}
      </span>
    </Button>
  );
};

const RemixMenu = ({
  onAction,
}: {
  onAction: (o: {
    action: RemixActionType;
    prompt?: string;
    allowImageGen?: boolean;
    allowResearch?: boolean;
    additionalReferences?: string;
    inspirationUrl?: string;
  }) => void;
}) => {
  const [activeOption, setActiveOption] = useState<RemixActionType | null>();
  const [isToolbarHovered, setIsToolbarHovered] = useState(false);
  const [promptInput, setPromptInput] = useState("");
  const [isImageGenEnabled, setIsImageGenEnabled] = useState(false);
  const [isResearchEnabled, setIsResearchEnabled] = useState(true);
  const [additionalReferences, setAdditionalReferences] = useState("");
  const [inspirationUrl, setInspirationUrl] = useState("");

  const handleOptionClick = (option: RemixActionType) => {
    setActiveOption(activeOption === option ? null : option);
  };

  const handleAction = () => {
    onAction({
      action: activeOption!,
      prompt: promptInput || undefined,
      allowImageGen: isImageGenEnabled,
      allowResearch: isResearchEnabled,
      additionalReferences: additionalReferences || undefined,
      inspirationUrl: inspirationUrl || undefined,
    });
    setPromptInput("");
    setActiveOption(null);
  };

  const renderOptionContent = () => {
    switch (activeOption) {
        case "edit":
        case "remix":
          return (
        <div className="flex flex-col space-y-2">
            <Textarea placeholder={`Enter your ${activeOption} prompt here...`} value={promptInput} onChange={e => setPromptInput(e.target.value)} />
            <Label className="flex align-bottom gap-4">
              <Switch checked={isImageGenEnabled} onCheckedChange={e => setIsImageGenEnabled(e)} />
              Allow Image Generation
            </Label>

            <Button onClick={handleAction}>Apply {activeOption}</Button>
          </div>)
      case "content":
        return (
          <div className="flex flex-col space-y-2">
            <Textarea placeholder={`Enter your ${activeOption} prompt here...`} value={promptInput} onChange={e => setPromptInput(e.target.value)} />
            <Textarea placeholder="Enter additional references here..." value={additionalReferences} onChange={e => setAdditionalReferences(e.target.value)} />

            <Label className="flex align-bottom gap-4">
              <Switch checked={isResearchEnabled} onCheckedChange={e => setIsResearchEnabled(e)} />
              Allow Research
            </Label>

            <Button onClick={handleAction}>Apply {activeOption}</Button>
          </div>
        );
      case "inspire":
        return (
          <div className="flex flex-col space-y-2">
            <Textarea placeholder={`Enter your ${activeOption} prompt here...`} value={promptInput} onChange={e => setPromptInput(e.target.value)} />
            <Input placeholder="Enter inspiration URL here..." value={inspirationUrl} onChange={x => setInspirationUrl(x.target.value)} />
            <Button onClick={handleAction}>Apply</Button>
          </div>
        );
      default:
        return null;
    }
  };

  const showLabels = activeOption !== null || isToolbarHovered;

  return (
    <div className="fixed left-4 top-1/2 transform -translate-y-1/2 flex">
      <div
        className="bg-background border rounded-lg shadow-lg p-2 space-y-2 flex flex-col"
        onMouseEnter={() => setIsToolbarHovered(true)}
        onMouseLeave={() => setIsToolbarHovered(false)}
      >
        <ToolbarItem
          icon={<Pencil className="h-4 w-4" />}
          label="Edit"
          isActive={activeOption === "edit"}
          showLabel={showLabels}
          onClick={() => handleOptionClick("edit")}
        />
        <ToolbarItem
          icon={<Wand2 className="h-4 w-4" />}
          label="Remix"
          isActive={activeOption === "remix"}
          showLabel={showLabels}
          onClick={() => handleOptionClick("remix")}
        />
        <ToolbarItem
          icon={<Wand2 className="h-4 w-4" />}
          label="Inspire"
          isActive={activeOption === "inspire"}
          showLabel={showLabels}
          onClick={() => handleOptionClick("inspire")}
        />
        <ToolbarItem
          icon={<FileText className="h-4 w-4" />}
          label="Content"
          isActive={activeOption === "content"}
          showLabel={showLabels}
          onClick={() => handleOptionClick("content")}
        />
        {
          /*
           <ToolbarItem
          icon={<PictureInPicture className="h-4 w-4" />}
          label="Image Regen"
          isActive={activeOption === "imageRegen"}
          showLabel={showLabels}
          onClick={() => handleOptionClick("imageRegen")}
        />*/
        }
        <ToolbarItem
          icon={<Trash2 className="h-4 w-4" />}
          label="Remove"
          isActive={activeOption === "remove"}
          showLabel={showLabels}
          onClick={() => onAction({ action: "remove" })}
        />
      </div>

      {activeOption && <div className="ml-2 bg-background border rounded-lg shadow-lg p-4 w-64">{renderOptionContent()}</div>}
    </div>
  );
};

const getSelector = (element: HTMLElement) => {
  const id = element.getAttribute("id");
  const name = element.getAttribute("name");
  const tag = element.tagName.toLowerCase();
  return `${tag}${id ? "#" + id : ""}${name ? `[name=${name}]` : ""}`;
};

interface PatchPromptPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (prompt: string) => void;
}

const PatchPromptPopup: React.FC<PatchPromptPopupProps> = ({ isOpen, onClose, onSubmit }) => {
  const [patchPrompt, setPatchPrompt] = useState("");

  const handleSubmit = () => {
    onSubmit(patchPrompt);
    setPatchPrompt("");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed right-12 bottom-32 bg-background border rounded-lg shadow-lg p-4 w-64 z-50">
      <div className="flex flex-col space-y-2">
        <Textarea 
          placeholder="Enter your patch instructions..."
          value={patchPrompt}
          onChange={e => setPatchPrompt(e.target.value)}
        />
        <div className="flex gap-2">
          <Button onClick={handleSubmit}>
            Apply Patch
          </Button>
          <Button variant="outline" onClick={() => {
            setPatchPrompt("");
            onClose();
          }}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export const SelectElementRemixPanel: React.FC<{
  onAction: (o: {
    selector?: string;
    action: string;
    prompt?: string;
    allowImageGen?: boolean;
  }) => void;
  iframeRef: React.RefObject<HTMLIFrameElement>;
}> = ({ iframeRef, onAction }) => {
  const { floatingRefs, currentElement, selectedElement, triggerSelect, floatingCssProps } = useSelectElement(iframeRef);
  const {
    floatingStyles: menuFloatingStyles,
    refs,
    update,
  } = useFloating({
    strategy: "fixed",
    placement: "left",
    whileElementsMounted: autoUpdate,
    open: !!selectedElement,
    elements: {
      reference: floatingRefs.selected.current ?? undefined,
    },
  });

  const selector = useMemo(() => (selectedElement ? getSelector(selectedElement) : undefined), [selectedElement]);
  const [showPatchPrompt, setShowPatchPrompt] = useState(false);

  useEffect(() => {
    if (!iframeRef.current) {
      console.log("No iframe");
    }
    if (iframeRef.current) {
      setTimeout(() => {
        triggerSelect();
      }, 500);
    }
    triggerSelect();
  }, [iframeRef.current]);

  return (
    <>
      <div className="absolute right-12 bottom-12 flex gap-2 z-50">
        <Button
          variant="secondary"
          className="rounded shadow-lg hover:shadow-xl transition-shadow duration-300"
          size="lg"
          onClick={() => setShowPatchPrompt(true)}
        >
          Patch Page
        </Button>
        <Button
          variant="secondary"
          className="rounded shadow-lg hover:shadow-xl transition-shadow duration-300"
          size="lg"
          onClick={() => onAction({ action: "end" })}
        >
          End Remixing
        </Button>
      </div>

      <PatchPromptPopup 
        isOpen={showPatchPrompt}
        onClose={() => setShowPatchPrompt(false)}
        onSubmit={(prompt) => onAction({ action: "patch", prompt })}
      />

      <div ref={floatingRefs.hover} className="bg-opacity-20 bg-orange-400 border-solid" style={{ ...floatingCssProps.hover }}></div>
      <div ref={floatingRefs.selected} className="border-solid border-orange-400 border-2" style={{ ...floatingCssProps.selected }}></div>
      <div ref={refs.setFloating} style={menuFloatingStyles} className={`border-solid border-gray-400 border-2 ${selectedElement ? "" : "invisible"}`}>
        <RemixMenu onAction={action => onAction({ selector, ...action })} />
      </div>
    </>
  );
};

export const SelectVariationRemixPanel = ({
  resource,
  trace,
  iframeRef,
}: {
  resource: UIResource;
  trace: Exclude<ReturnType<typeof getSuspendTraceData<SelectVariationToApply>>, null>;
  iframeRef: React.RefObject<HTMLIFrameElement>;
}) => {
  const data = getResourceOutput(resource);
  const [selectedVariation, setSelectedVariation] = useState(1);
  const resourceApi = useResourceWSApi();
  const variations = [
    {
      name: "Original",
      ref: undefined,
      data: data,
    },
    ...trace.args[0].variations.map((v, i) => ({
      name: `Variation #${i + 1}`,
      ref: i,
      data: v,
    })),
  ];
  const preview = variations[selectedVariation].data;
  const selector = trace.args[0].selector;

  const scrollToElement = useCallback(
    (selector: string) => {
      if (!iframeRef.current || !iframeRef.current.contentDocument) {
        return;
      }

      const iframeWindow = iframeRef.current?.contentWindow;
      const iframeDoc = iframeRef.current?.contentDocument;
      function s() {
        const el = iframeDoc.querySelector(selector) as HTMLElement;
        const bounding = el?.getBoundingClientRect();
        if (!el || !bounding) {
          return;
        }
        const scroll = el.offsetTop;
        iframeWindow?.scrollTo(0, scroll);
      }
      if (iframeDoc.readyState === "complete") {
        s();
      } else {
        iframeDoc.addEventListener("DOMContentLoaded", s);
      }
    },
    [iframeRef.current?.contentWindow],
  );
  useEffect(() => {
    if (!iframeRef.current?.contentWindow) {
      return;
    }
    const scrollPos = iframeRef.current.contentWindow?.scrollY ?? 0;
    iframeRef.current.contentDocument?.open();
    iframeRef.current.contentDocument?.write(preview ?? "");
    iframeRef.current.contentDocument?.close();
    iframeRef.current.contentWindow?.scrollTo(0, scrollPos);
    requestAnimationFrame(() => {
      scrollToElement(selector);
    });
  }, [preview, scrollToElement, selector]);

  const run = useCallback(async (varationRef: number | undefined) => {
    await resourceApi.release(
      {
        rootResourceId: trace.rootContext,
        triggeringResourceId: trace.contextName,
      },
      {
        args: trace.args,
        result: {
          selected: varationRef,
        },
      },
    );
  }, []);

  useEffect(() => {
    scrollToElement(selector);
  }, [scrollToElement, selector]);

  return (
    <>
      <div className="flex flex-row items-center p-4 gap-10">
        {variations.map((v, i) => (
          <Button
            className={`w-[135px] h-[192px] bg-white overflow-hidden p-0 m-0 relative  hover:border-orange-500 hover:border-2 ${i === selectedVariation ? "border-2 border-orange-500" : ""}`}
            key={i}
            onClick={() => run(v.ref)}
            onMouseMove={() => setSelectedVariation(i)}
          >
            <div className="absolute bottom-0 bg-black text-white left-0 right-0 text-xl p-2 z-50">{v.name}</div>
            <div>
              <iframe
                srcDoc={v.data}
                style={{
                  transform: "scale(0.15)",
                  transformOrigin: "50% 50%",
                  pointerEvents: "none",
                }}
                onLoad={x => {
                  const iframe = x.currentTarget;
                  requestAnimationFrame(() => {
                    console.log(iframe.contentDocument?.querySelector(selector));
                    console.log(iframe.contentDocument?.querySelector(selector));
                    iframe.contentDocument?.querySelector(selector)?.scrollIntoView();
                  });
                }}
                className="min-w-[900px] min-h-[1280px] bg-white"
              />
            </div>
          </Button>
        ))}
      </div>
    </>
  );
};

const useSelectElement = (iframeRef: React.RefObject<HTMLIFrameElement>) => {
  const hoverFloatingRef = useRef<HTMLDivElement>(null);
  const selectedFloatingRef = useRef<HTMLDivElement>(null);
  const [currentElement, setCurrentElement] = useState<HTMLElement | null>(null);
  const [selectedElement, setSelectedElement] = useState<HTMLElement | null>(null);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const updateFloatingElement = useCallback(
    (ref: HTMLDivElement, element: HTMLElement) => {
      if (!iframeRef.current) {
        return;
      }

      const iframeRect = iframeRef.current.getBoundingClientRect();

      const rect = element.getBoundingClientRect();

      const width = rect.width;
      const left = rect.left + iframeRect.left;
      const top = Math.max(0, rect.top) + iframeRect.top;
      const visibleHeight = Math.max(0, Math.min(rect.bottom, iframeRect.height) - Math.max(rect.top, 0));

      ref.style.width = width + "px";
      ref.style.height = visibleHeight + "px";
      ref.style.left = left + "px";
      ref.style.top = top + "px";
    },
    [iframeRef.current],
  );

  const updatePos = useCallback(() => {
    if (!iframeRef.current) {
      return;
    }
    if (currentElement && hoverFloatingRef.current) {
      updateFloatingElement(hoverFloatingRef.current, currentElement);
    }
    if (selectedElement && selectedFloatingRef.current) {
      updateFloatingElement(selectedFloatingRef.current, selectedElement);
    }
  }, [hoverFloatingRef.current, selectedFloatingRef.current, selectedElement, currentElement, iframeRef.current]);

  useEffect(() => {
    if (!iframeRef.current) {
      return;
    }
    const iframe = iframeRef.current;
    if (!iframe.contentWindow) {
      return;
    }
    iframe.contentWindow.document.addEventListener("scroll", updatePos);
    iframe.contentWindow.addEventListener("resize", updatePos);
    updatePos();
    return () => {
      iframe.contentWindow?.removeEventListener("scroll", updatePos);
      iframe.contentWindow?.removeEventListener("resize", updatePos);
    };
  }, [iframeRef.current, updatePos]);

  useEffect(() => {
    if (!iframeRef?.current?.contentDocument) {
      console.log("No iframe");
      return;
    }
    if (!isSelectMode) {
      return;
    }

    const iframe = iframeRef.current;
    let selectedElement: HTMLElement | null = currentElement;
    let markElement = (e: MouseEvent) => {
      const elems = iframe.contentDocument?.elementsFromPoint(e.clientX, e.clientY) || [];
      const elem = elems.find(x => ["SECTION", "HEADER", "FOOTER"].includes(x.tagName.toUpperCase()));
      if (elem) {
        selectedElement = elem as HTMLElement;
        setCurrentElement(elem);
      }
    };
    const select = (e: MouseEvent) => {
      setSelectedElement(selectedElement);
    };
    iframe.contentDocument?.addEventListener("mousedown", select);
    iframe.contentDocument?.addEventListener("mousemove", markElement);

    return () => {
      iframe.contentDocument?.removeEventListener("mousedown", select);
      iframe.contentDocument?.removeEventListener("mousemove", markElement);
      setCurrentElement(null);
    };
  }, [iframeRef.current?.contentDocument, isSelectMode]);

  return {
    floatingRefs: {
      hover: hoverFloatingRef,
      selected: selectedFloatingRef,
    },
    currentElement,
    selectedElement,
    stopSelect: () => {
      setIsSelectMode(false);
    },
    triggerSelect: () => {
      setIsSelectMode(false);
      requestAnimationFrame(() => {
        setIsSelectMode(true);
        //setSelectedElement(null)
        iframeRef.current?.focus();
      });
    },
    floatingCssProps: {
      hover: {
        pointerEvents: "none",
        position: "fixed",
        cursor: "pointer",
        display: isSelectMode && currentElement ? "block" : "none",
      } as const,
      selected: {
        pointerEvents: "none",
        position: "fixed",
        display: selectedElement ? "block" : "none",
      } as const,
    },
  };
};

import { SessionProvider } from "@hono/auth-js/react";
import { Provider } from "jotai";
import React from "react";
import ReactDOM from "react-dom/client";
import { j_rootStore } from "./state";
import { App } from "./app";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={j_rootStore}>
      <React.Suspense fallback={<div>Loading...</div>}>
        <SessionProvider>
          <App />
        </SessionProvider>
      </React.Suspense>
    </Provider>
  </React.StrictMode>,
);

import React, { type FC, useRef, useState, useTransition, useEffect, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";

export function withErrorBounderyFallBack<Props>(Comp: FC<Props>) {
  return (props: Props) => {
    const oldP = useRef<Props | undefined>(props);
    const handledPropsRef = useRef<Props | undefined>(undefined);
    const newPRef = useRef<Props | undefined>(undefined);
    const errorHandled = useRef<Error | undefined>(undefined);
    const [newP, setNewP] = useState<Props | undefined>(undefined);
    const [isUpdating, start] = useTransition();
    useEffect(() => {
      if (handledPropsRef.current === props) {
        return;
      }
      handledPropsRef.current = props;
      if (!oldP.current) {
        oldP.current = props;
      } else if (newPRef.current) {
        oldP.current = newPRef.current;
      }
      newPRef.current = props;
      start(() => {
        setNewP(props);
      });
    }, [JSON.stringify(props), start]);
    return (
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => {
          if (errorHandled.current === error) {
            return null;
          }
          errorHandled.current = error;
          if (newPRef.current !== undefined) {
            setNewP(undefined);
            newPRef.current = undefined;
          } else {
            oldP.current = undefined;
          }
          return "error boundary";
        }}
      >
        <Suspense>
          <Comp {...(newP ?? oldP.current)} />
        </Suspense>
      </ErrorBoundary>
    );
  };
}

import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { type AnyRoute, createRoute, Outlet, useLoaderData, useRouter } from "@tanstack/react-router";
import { ofetch } from "ofetch";

type User = {
  id: string;
  name: string;
  email: string;
  isAllowed: boolean;
};

export const adminUsersRoute = <TParentRoute extends AnyRoute = AnyRoute>(parent: TParentRoute) => {
  const r = createRoute({
    getParentRoute: () => parent,
    path: "/users",
    loader: async () => {
      const users = await ofetch<{ users: User[] }>("/api/users");
      return users;
    },
    component: function Playground() {
      const users = r.useLoaderData().users;
      const router = useRouter();

      return (
        <div>
          <h1 className="text-3xl font-semibold">Users</h1>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Enroll</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {users.map(user => (
                <TableRow key={user.id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Switch
                      disabled={user.isAllowed}
                      onCheckedChange={async () => {
                        await ofetch(`/api/users/${user.id}/enroll`, {
                          method: "POST",
                        });
                        await router.invalidate();
                      }}
                      checked={user.isAllowed}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      );
    },
  });
  return r;
};
